import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useSelector } from 'react-redux';

import { selectMultipleLineOfBusiness } from '@/core/store/reducers/staticContent';

import { Content } from '@/features/MultiLine/components/Content';
import { selectLobId } from '@/features/Config/store/selectors';

import {
  ExpandMoreArrow,
  Label,
  SelectWrapper,
  StyledSelect,
} from './Select.styles';

const Select = () => {
  const [
    isOpen,
    setOpen,
  ] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen(prev => !prev);
  }, [isOpen]);

  const handleClickAway = () => {
    setOpen(false);
  };
  const LobId = useSelector(selectLobId);
  const { data } = useSelector(selectMultipleLineOfBusiness);

  const findIsSelectedLineOfbusiness =
    () => data?.find(array => Number(array.lobId) === Number(LobId));
  const selectedLineOfbusiness = useMemo(
    () => findIsSelectedLineOfbusiness(), [findIsSelectedLineOfbusiness]
  );

  return (
    <SelectWrapper>
      <Label>Line of Business</Label>
      <ClickAwayListener onClickAway={handleClickAway}>
        <StyledSelect
          onClick={toggleOpen}
        >
          {selectedLineOfbusiness?.name || 'Not Selected '}
          <ExpandMoreArrow
            isExpanded={isOpen}
            fontSize="small"
            color="inherit"
          />
        </StyledSelect>
      </ClickAwayListener>
      {isOpen && <Content />}
    </SelectWrapper>
  );
};

export { Select };
