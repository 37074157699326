import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import {
  MetaData,
  SelectOption,
} from '@/core/interfaces/common';
import { RootState } from '@/core/interfaces/store';

import { request } from '@/utils/request';

import { RiskLevel } from './claimDetails';

const prepareSelectOptions = (obj: { [key: string]: string } = {}) => Object.entries(obj).map(([
  key,
  value,
]) => ({
  label: value,
  value: key,
}));

export type Note = {
  adjusterNoteId: number;
  noteDesc: string;
  noteDate: string;
  noteLag: number;
  anyRedFlag: boolean;
  flagsCount: number;
  isStarred: boolean;
  tmFlag: Array<TmFlag>;
}

type TmFlag = {
  flag: string;
  riskLevel: RiskLevel;
  startChar: number;
  endChar: number;
}

type NoteResponse = {
  metadata?: MetaData;
  data: Array<Note>;
}

type AdditionalFitlers = {
  riskLevel?: boolean;
  riskCharacteristics?: Array<SelectOption>;
  starredNotesOnly?: boolean;
  timePeriod?: SelectOption;
  noteType?: Array<SelectOption>;
}

export type NotesState = {
  data: Array<Note>;
  metadata?: MetaData;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  filters: NoteFiltersData | { [key: string]: never };
  filtersStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  additionalFilters: AdditionalFitlers;
  feedbackError: string | null;
  feedbackStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
}

type NoteFiltersData = {
  timePeriod: { [key: string]: string };
  riskLevelFilters: { [key: string]: string };
  riskCharacteristics: { [key: string]: string };
  noteTypes: { [key: string]: string };
  claimDetailNotesColumnsList: { [key: string]: string };
}

export type NoteFilters = {
  metadata?: MetaData;
  data: NoteFiltersData;
}

const initialState: NotesState = {
  data: [],
  filters: {},
  additionalFilters: {},
  metadata: {},
  status: 'idle',
  feedbackStatus: 'idle',
  filtersStatus: 'idle',
  error: null,
  feedbackError: null,
};

export const fetchNotes = createAsyncThunk(
  'notes/fetchNotes',
  async ({
    claimId, props,
  }: { claimId: number | string; props: { [key: string]: any } }) => {
    const params = {
      ...props,
      claimId,
    };
    const { data } = await request.get<NoteResponse>('claimDetailNotesList', { params });

    return data;
  }
);

export const fetchNotesFilters = createAsyncThunk(
  'notes/fetchNotesFilters',
  async (claimId: number | string) => {
    const params = {
      clientId: 1,
      claimId,
    };

    const { data } = await request.get<NoteFilters>('claimDetailNotesFilters', { params });

    return data;
  }
);

type StarProps = {
  adjusterNoteId: number;
  userEmail: string;
}

export type NoteFeedback = {
  adjusterNoteId: string;
  feedbackReason: string;
  feedbackDescription: string;
}

export const starNote = createAsyncThunk(
  'notes/starNote',
  async ({
    adjusterNoteId,
    userEmail,
  }: StarProps) => {
    const params = {
      userEmail,
      adjusterNoteId,
    };

    request.get<NoteResponse>('starAdjusterNote', { params });

    return adjusterNoteId;
  }
);

export const unStarNote = createAsyncThunk(
  'notes/unStarNote',
  async ({
    adjusterNoteId,
    userEmail,
  }: StarProps) => {
    const params = {
      userEmail,
      adjusterNoteId,
    };

    request.get<NoteResponse>('unStarAdjusterNote', { params });

    return adjusterNoteId;
  }
);

export const sendFeedback = createAsyncThunk(
  'notes/sendFeedback',
  async ({
    adjusterNoteId,
    feedbackReason,
    feedbackDescription,
  }: NoteFeedback, { rejectWithValue }) => {
    try {
      const params = {
        adjusterNoteId,
        feedbackReason,
        feedbackDescription,
      };

      return request.get('claimDetailNotesFeedback', { params });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setAdditionalFilters: (state, action) => {
      state.additionalFilters = action.payload;
    },
    resetAdditionalFilters: state => {
      state.additionalFilters = {};
    },
    resetFeedbackStatus: state => {
      state.feedbackStatus = 'idle';
      state.feedbackError = null;
    },
    resetNotes: state => {
      state.data = [];
      state.metadata = {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchNotes.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.metadata = action.payload.metadata;
        state.data = action.payload.data;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(starNote.fulfilled, (state, action) => {
        const note = state.data.find(item => item.adjusterNoteId === action.payload);

        if (note) {
          note.isStarred = true;
        }
      })
      .addCase(fetchNotesFilters.pending, state => {
        state.filtersStatus = 'loading';
      })
      .addCase(fetchNotesFilters.fulfilled, (state, action) => {
        state.filtersStatus = 'succeeded';
        state.filters = action.payload.data;
      })
      .addCase(fetchNotesFilters.rejected, (state, action) => {
        state.filtersStatus = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(unStarNote.fulfilled, (state, action) => {
        const note = state.data.find(item => item.adjusterNoteId === action.payload);

        if (note) {
          note.isStarred = false;
        }
      })
      .addCase(sendFeedback.pending, state => {
        state.feedbackStatus = 'loading';
      })
      .addCase(sendFeedback.fulfilled, state => {
        state.feedbackError = null;
        state.feedbackStatus = 'succeeded';
      })
      .addCase(sendFeedback.rejected, (state, action) => {
        state.feedbackStatus = 'failed';
        state.feedbackError = action.error.message || null;
      });
  },
});

export default notesSlice.reducer;

export const {
  setAdditionalFilters,
  resetAdditionalFilters,
  resetFeedbackStatus,
  resetNotes,
} = notesSlice.actions;

const selectState = (state: RootState) => state.notes;

export const selectNotesData = createSelector(selectState, state => state);
export const selectNotesFilters = createSelector(selectState, state => state.filters);
export const selectNotesFiltersLoading = createSelector(selectState, state => state.filtersStatus);
export const selectNotesLoading = createSelector(selectState, state => state.status === 'loading');
export const selectAdditionalFilters = createSelector(
  selectState, state => state.additionalFilters
);
export const selectRiskCharacteristicsOptions = createSelector(
  selectState, state => prepareSelectOptions(state.filters.riskCharacteristics));
export const selectNoteTypesOption = createSelector(
  selectState, state => prepareSelectOptions(state.filters.noteTypes)
    .sort((a, b) => a.label.localeCompare(b.label)));
export const selectTimePeriodOption = createSelector(
  selectState, state => prepareSelectOptions(state.filters.timePeriod));
export const selectIsNoteFeedbackReady = createSelector(selectState, state => state.feedbackStatus === 'succeeded' || state.feedbackStatus === 'failed');
export const selectFeedbackStatus = createSelector(selectState, state => state.feedbackStatus);
