import {
  ReactNode,
  useEffect,
} from 'react';
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { useRouter } from 'next/router';
import { InteractionStatus } from '@azure/msal-browser';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { routePaths } from '@/core/constants/routes';
import {
  getTooltips, selectSelectedLineOfBusiness,
} from '@/core/store/reducers/staticContent';

import {
  initializeApp,
} from '@/features/Config/store/actions';
import {
  selectLobId, selectUser,
} from '@/features/Config/store/selectors';
import { getScoreColorSchema } from '@/features/CT/ClaimList/store/slice/claims';

import useAuthWrapperHook from './useAuthWrapperHook';

type AuthWrapperProps = {
  children: ReactNode;
}

export const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { inProgress } = useMsal();

  const selectedLineOfbusiness = useSelector(selectSelectedLineOfBusiness);

  const user = useSelector(selectUser);
  const router = useRouter();
  const lobId = useSelector(selectLobId);

  const { fetchLobData } = useAuthWrapperHook();

  useEffect(() => {
    if (!lobId) return;
    const handleRouteChange = (url: string) => {
      const dashedLowerUrlString = (accessList: any) => accessList.accessName.toLowerCase().replace(/\s+/g, '-');
      const isUrlPermission = selectedLineOfbusiness
        ?.accessList?.some(accessList => url.includes(dashedLowerUrlString(accessList)));

      const isGlobalUrl = [
        routePaths.home,
        routePaths.help,
      ].some(path => url.endsWith(path));

      if (!isUrlPermission && !isGlobalUrl && !url.includes('_error')) {
        router.push({
          pathname: routePaths.error,
          query: { statusCode: 403 },
        });
      }
    };

    handleRouteChange(router.pathname);
  }, [
    router.pathname,
    lobId,
  ]);

  useEffect(() => {
    if (router.isReady && isAuthenticated) {
      router.replace({
        query: {
          ...router.query,
          lobId,
        },
      });
    }
  }, [
    lobId,
    router.pathname,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(initializeApp());
      dispatch(getScoreColorSchema());
      dispatch(getTooltips());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchLobData();
    }
  }, [user.data?.email]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None &&
      !isAuthenticated) {
      router.push({
        pathname: routePaths.login,
        query: {
          redirectUrl: router.asPath === '/' ? undefined : router.asPath,
        },
      });
    }
  }, [
    isAuthenticated,
    inProgress,
  ]);

  return (
    <AuthenticatedTemplate>
      {isAuthenticated && user.data?.email ?
        children :
        null}
    </AuthenticatedTemplate>
  );
};
